<template>
    <div class="mx-auto max-w-5xl lg:max-w-7xl  clear-both pt-1.5">
        <template v-if="website.search.products_total==0">
            <div class="w-full font-bold">Helaas geen resultaten gevonden die voldoen aan jouw zoekopdracht.</div>
            <br/>
        </template>
        <div
            class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-3  lg:grid-cols-4 xl:gap-x-6">
            <div v-for="(product,index) in website.search.products" :key="product.id"
                          class=" group relative border-gray-100 rounded-2xl p-0 sm:p-3 cursor-pointer
                          hover:border-gray-400
                          hover:scale-110 bg-white
                        transition ease-in-out delay-150
                        text-gray-700 dark:text-white dark:bg-gray-700
                          ">

                <div class="p-0.5 px-2 absolute right-0 text-white z-10 bg-white h-6 rounded mr-1"  @click="addFavourite(product.variant_id)">
                    <CustomSvg name="favourite-true"  customClass="fill-blue-800 pr-2" v-if="isFavourite(product.variant_id)"></CustomSvg>
                    <CustomSvg name="favourite-false"  customClass="fill-blue-800 pr-2" v-if="!isFavourite(product.variant_id)"></CustomSvg>
                </div>
                <jet-nav-link :href="product.product_url" class="!no-underline">
                  <span v-if="product.price_percentage!==0 && product.price_percentage!=='0'" class="bg-green-700 rounded p-0.5 px-2 absolute text-white z-10">
                        {{ product.price_percentage }} %
                    </span>
                    <div class="group relative mx-auto w-full rounded-lg dark:bg-white p-1">
                        <div class="hero container max-w-screen-lg mx-auto  h-44 w-full flex items-center max-w" :ref="'imgBox' + index" :id="'imgBox' + index">
                            <img v-if="!product.size_x" :src="product.image+'&fix-height=1'" class="max-h-44 mx-auto border-b"
                                 :loading="index <9 ? 'eager' :'lazy'"
                                 :alt="product.name"/>
                            <img v-else :src="product.image" class="mx-auto max-w-none"
                                 :style="{width:getImgWidth(product),height:getImgHeight(product)}"
                                 :loading="index <9 ? 'eager' :'lazy'"
                                 :alt="product.name"/>
                        </div>
                    </div>
                    <h3 class="text-xs sm:text-sm  no-underline h-24 mt-3">
                        {{ product.name.substring(0, 80) }}<span v-if="product.name.length > 80">...</span>
                    </h3>
                    <div v-if="Object.keys(product.webshops).length> 1" class="text-xs font-light float-left mt-5">
                        {{ Object.keys(product.webshops).length }} prijzen
                    </div>
                    <div class="text-sm font-medium float-right text-sm text-xl font-bold mt-2">
                        &euro; {{ product.price_human }}
                    </div>

                </jet-nav-link>
            </div>
        </div>
    </div>

</template>


<script>

import JetNavLink from '@/Jetstream/NavLink.vue'
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';

export default {

    components: {
        CustomSvg,
        JetNavLink
    },
    props: {
        products: Object,
        website: Object
    },

    data() {
        return {
            infoHeight: Number,
            infoWidth: Number,
        }
    },
    methods: {
        setRefs() {
            this.infoWidth = this.$refs['imgBox0'][0].clientWidth;
            this.infoHeight = this.$refs['imgBox0'][0].clientHeight;
        },
        isNumeric(num) {
            return !isNaN(num)
        },
        getImgWidth(product) {
            if (!this.isNumeric(this.infoHeight)) {
                return '100%';
            }
            if (product.size_y - this.infoHeight < product.size_x - this.infoWidth) {
                return this.infoWidth+'px';
            } else {
                return Math.floor(product.size_x / (product.size_y / this.infoHeight))+'px';
            }
        },
        getImgHeight(product) {
            if (!this.isNumeric(this.infoHeight)) {
                return 'auto;';
            }
            if (product.size_x - this.infoWidth < product.size_y - this.infoHeight) {
                return this.infoHeight+'px';
            } else {
                return Math.floor(product.size_y / (product.size_x / this.infoWidth))+'px';
            }
        },
    },
    mounted() {
        if( window && typeof window !== 'undefined') {
            window.addEventListener('resize', this.setRefs);
        }
        this.setRefs();
        //this.calculateDimensions();
    },
    unmounted() {
        if( window && typeof window !== 'undefined') {
            window.removeEventListener('resize', this.setRefs);
        }
    },
}
</script>

